import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Link } from "@components/shared/sub";
import { Maybe, Query, SanityMedia } from "@graphql-types";
import { Container, GridContainer, H2, H3, P } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  margin: 130px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px 0;
  }
`;

const Clickable = styled(Container)<{ isSelected?: boolean }>`
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
  padding: ${(props) => (props.padding ? props.padding : `10px 35px`)};
  background-color: ${(props) =>
    props.isSelected ? colors.darkBlue : colors.transparent};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: fit-content;
  }
`;

const Text = styled.p<{ isSelected?: boolean }>`
  margin: 0;
  font-size: 20px;
  opacity: ${(props) => (props.isSelected ? 1 : 0.4)};
  color: ${(props) => (props.isSelected ? colors.white : colors.darkBlue)};
`;

const ClickableContainer = styled(Container)`
  margin-bottom: 80px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const ComingSoon = styled(H2)`
  border-bottom: 1.5px solid ${colors.darkBlue};
  line-height: 80px;
`;

const AllMedia = () => {
  const { allSanityMediaType, allSanityMedia }: Query = useStaticQuery(graphql`
    {
      allSanityMediaType {
        nodes {
          _id
          category {
            title
          }
        }
      }
      allSanityMedia {
        nodes {
          _id
          category {
            _id
            category {
              title
            }
          }
          title
          summary
          slug {
            current
          }
        }
      }
    }
  `);

  const [filter, setFilter] = useState("all");

  const handleClick = (id?: string | null) => {
    setFilter(id ?? "all");
  };

  const Media = (props: Maybe<SanityMedia>) => {
    if (props == null) {
      return null;
    }
    return (
      <Container flexDirection="column">
        <H3 margin="0">{props.title}</H3>
        <P>{props.summary}</P>

        <Link
          isUnderlined
          linktext="Find out more"
          url={`/media/${props.slug?.current}` ?? "/"}
          color="darkBlue"
          opacity={0.5}
        />
      </Container>
    );
  };

  const data =
    filter === "all"
      ? allSanityMedia?.nodes
      : allSanityMedia?.nodes?.filter(
          (type) => type && type.category?._id === filter
        );

  const Medias = () => {
    if (data == null || data.length === 0) {
      return (
        <Container
          paddingHorizontal={100}
          justifyContent="center"
          alignItems="center"
        >
          <ComingSoon>Media Coming Soon</ComingSoon>
        </Container>
      );
    }

    return (
      <GridContainer repeat={3} mobileRepeat={1} rowGap="30px" columnGap="80px">
        {data.map((media) => {
          // @ts-ignore
          return <Media key={media?._id} {...media} />;
        })}
      </GridContainer>
    );
  };

  return (
    <Wrapper>
      <Container flexDirection="column" width="70%" margin="auto">
        <ClickableContainer>
          <Clickable
            isSelected={filter === "all"}
            onClick={() => handleClick("all")}
          >
            <Text isSelected={filter === "all"}>All Articles</Text>
          </Clickable>
          {allSanityMediaType.nodes.map((type) => {
            if (type == null) {
              return null;
            }

            return (
              <Clickable key={type._id} isSelected={filter === type._id}>
                <Text
                  isSelected={filter === type._id}
                  onClick={() => handleClick(type._id)}
                >
                  {type.category?.title}
                </Text>
              </Clickable>
            );
          })}
        </ClickableContainer>
        <Medias />
      </Container>
    </Wrapper>
  );
};

export default AllMedia;
