import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "@components/shared/hero";
import { Query } from "@graphql-types";

const MediaHero = () => {
  const { sanityMediaPage }: Query = useStaticQuery(graphql`
    {
      sanityMediaPage {
        hero {
          content {
            _rawRichTextContent
          }
          links {
            url
            newwindow
            linktext
            internallink
            _key
          }
        }
      }
    }
  `);

  return <Hero {...sanityMediaPage?.hero} height="50vh" />;
};

export default MediaHero;
