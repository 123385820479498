import React from "react";
import { graphql, PageProps } from "gatsby";

import ContactUsBlock from "@components/contact/contactUsBlock";
import SEO from "@components/shared/seo";
import MediaHero from "@components/media/mediaHero";
import AllMedia from "@components/media/allMedia";
import { Query } from "@graphql-types";
interface Props extends PageProps {
  data: Query;
}

export default function Media({ data }: Props) {
  const { sanityMediaPage } = data;

  return (
    <div>
      <SEO seoData={sanityMediaPage?.seo} />
      <MediaHero />
      <AllMedia />
      <ContactUsBlock />
    </div>
  );
}

export const query = graphql`
  query MediaQuery {
    sanityMediaPage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
